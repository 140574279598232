@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@100;500&display=swap');



#welcome-name-text {
    background: 50% 100% / 50% 50% no-repeat radial-gradient(ellipse at bottom, #fff, transparent, transparent);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: 10vw;
    font-family: "Source Sans Pro", sans-serif;
    animation: reveal 3000ms ease-in-out forwards 200ms,
    glow 2500ms linear infinite 2500ms;
}

@keyframes reveal {
    80%{
        letter-spacing: 8px;
    }
    100% {
        background-size: 300% 300%;
    }
}
@keyframes glow {
    40% {
        text-shadow: 0 0 3px #fff;
    }
}








.bg-image {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -100;
    width: 100%;
    height: 100%;
    filter: brightness(0.35);

}

.introduction {
    color: white;
    font-size: 10em;
    font-weight: bolder;
    align-self: center;
}

.intro-container {
    height: 100vh;
    display: flex;

    flex-direction: column;
    align-items: center;
    justify-content: center;


}

#node{
    color: #3c873a ;
}
#node:hover{
    color: white ;
}

.desc {
    font-size: 3.5rem;
    color: white;
}

#react {
    color:#5E97D0;
}

#react:hover {
    color: white;
}

#android {
    color: #a4c639;
}

#android:hover {
    color: white;
}

.skill-for-hover:hover {
    color: white;
}




@media screen and (max-width: 1023px) {
    #welcome-name-text {
        background: 50% 100% / 50% 50% no-repeat radial-gradient(ellipse at bottom, #fff, transparent, transparent);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        font-size: 15vw;
        font-family: "Source Sans Pro", sans-serif;
        animation: reveal 3000ms ease-in-out forwards 200ms,
        glow 2500ms linear infinite 2000ms;
    }




    @keyframes reveal {
        80%{
            letter-spacing: 1.8px;
        }
        100% {
            background-size: 300% 300%;
        }
    }

    .bg-image {
        position: absolute;
        right: 0;
        top: 0;
        z-index: -100;
        width: 100%;
        height: 100%;
        filter: brightness(0.35);

    }

    .introduction {
        color: white;
        font-size: 2em;
        font-weight: bolder;
        align-self: center;
    }

    .desc {
        font-size: 1.1rem;
        color: white;
    }

    .intro-container {
        height: 89vh;
        display: flex;

        flex-direction: column;
        align-items: center;
        justify-content: center;
    }


}

.name-anime {
    animation: 4s name ease-out;
    z-index: 9;
}

@keyframes name {

    0% {

        transform: translateY(-50vh);
    }


}

.description-anime {
    animation: 3s descLoading ease-in-out;

    height: auto;
    text-align: center;

}

@keyframes descLoading {

    0% {

        opacity: 0;
    }
    100% {

        opacity: 1;
    }

}

button {
    position: relative;
    cursor: pointer;
    outline: none;
    border: 0;
    margin-top: 5%;
    vertical-align: middle;
    text-decoration: none;
    background: transparent;
    padding: 0;
    font-size: inherit;
    font-family: inherit;
}

button.about-me {
    width: 12rem;
}

button.about-me .circle {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: relative;
    display: block;
    margin: 0;
    width: 3rem;
    height: 3rem;
    background: #ffffff;
    border-radius: 1.625rem;
}

button.about-me .circle .icon {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #ffffff;
}

button.about-me .circle .icon.arrow {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    left: 0.625rem;
    width: 1.125rem;
    height: 0.125rem;
    background: none;

}

button.about-me .circle .icon.arrow::before {
    position: absolute;
    content: "";
    top: -0.29rem;
    right: 0.0625rem;
    width: 0.625rem;
    height: 0.625rem;
    border-top: 0.125rem solid #000000;
    border-right: 0.125rem solid #000000;
    transform: rotate(45deg);
}

button.about-me .button-text {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0.75rem 0;
    margin: 0 0 0 1.85rem;
    color: #ffffff;
    font-weight: 700;
    line-height: 1.6;
    text-align: center;
    text-transform: uppercase;
}

button:hover .circle {
    width: 100%;
}

button:hover .circle .icon.arrow {
    background: #000000;
    transform: translate(1rem, 0);
}

button:hover .button-text {
    color: #000000;
}

