#card{

    padding: 10px;
    text-align: center;
    height:100%;
    border: 5px white solid;
    border-radius: 10px;
    color: #ffffff;

    backdrop-filter: brightness(50%);
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;



}


.proj-img{
    width: 95%;
    height: auto;
    align-self: center;
    margin-bottom: 1.5rem;
}
.proj-img:hover{
    transform: scale(1.2);
}

@media screen and (max-width: 1023px){

    #card{

        padding: 20px;
        text-align: center;
        height: auto;
        border: 5px white solid;
        color: white;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;


    }

    .proj-img{
        width: 98%;
        height: auto;
        align-self: center;
        margin-bottom: 1rem;
    }
    .proj-img:hover{

    }


}

@keyframes fade-in {
    0%{
        transform: translateX(-100%);
    }100%{
             transform: translateX(0);

         }

}