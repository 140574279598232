.layout-grid{
    width: 90%;
    height: auto;

}
.layout-wrapper {
    width: 100%;
    background-color: transparent;

    display: grid;
    grid-template-columns: repeat(3, auto);
    gap: 30px;
    grid-auto-rows: auto;

    margin-left: 6%;
    margin-right: 6%;
}

@media screen and (max-width: 1023px){
    .layout-wrapper {
        max-width: 100%;
        height: auto;
        background-color: transparent;
        display: grid;
        grid-template-columns: repeat(1, 1fr);

        margin-left: 6%;
        margin-right: 6%;
    }


}

