.noPage-body{
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    padding: 5%;
}

#crying-boy{

    height:auto;
    width: 35%;
}
.noPage-text{
    height:  25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}
@media screen and (max-width: 1023px){
    .noPage-body{
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content:center;
        height: 100%;
    }

    #crying-boy{
        align-self: center;
        height: auto;
        width: 70%;
    }

    .noPage-text{
        height:  auto;
        margin-top: 6%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;


    }



}