.skills_display {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-content: center;
    padding: 2rem;
    background: transparent;
    margin-top: 5%;
    margin-bottom: 6%;


}

.skill_logo{
    width: 8%;
    aspect-ratio: auto;
}

@media screen and (max-width: 1023px) {

    .skills_display {
        margin-top: 0;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-content: center;
        justify-content: space-between;

    }

    .skill_logo{
        width: 30%;
        aspect-ratio: auto;
        margin: 2rem;
    }



}