#detail-body-project{

    margin-top: 3%;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-content: center;


}
#right-detail{
    align-self: start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100% ;
}
.top-detail{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.detail-proj-img{

    width: 45%;
    align-self: center;
    border: 5px white solid;
}
#proj-detail-name{
    margin-top: 2%;
    font-weight: bold;
    font-size: 4em;
    color: white;
    text-align: center;

}
#proj-detail-technologies{
    color: white;
    font-weight: bold;
    font-size: 2em;
    align-self: start;
    text-align: left;
    margin-top: 2%;

}
#technology-list{
    align-self: end;
    color: white;

    margin-left: 2rem;
    font-size: 1.3em;


}
#used-technology{
        margin-top: 20%;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}

@media screen and (max-width: 1023px){

    .top-detail{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    #proj-detail-name{
        margin-top: 2%;
        font-weight: bolder;
        font-size: 2em;
        color: white;
        text-align: center;

    }
    #proj-detail-technologies{
        color: white;
        font-weight: bold;
        font-size: 2em;
        align-self: center;
        text-align: center;
        margin-top: 2%;

    }



    #used-technology{
        margin-top: 20%;
        align-self: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
    }
    #technology-list{
        align-self: center;
        color: white;

        margin-left: 2rem;
        font-size: 1.5em;


    }
    .detail-proj-img{

        width: 85%;
        border: 5px white solid;
        align-self: center;
        margin-top: 3%;
        margin-bottom: 3%;
    }
    #right-detail{
        align-self: center;
        display: flex;
        flex-direction: column;
        justify-content:center;
        align-items: center;
        height: 100% ;
    }


}