#footer-body{

    border-radius: 100px 15px 100px 10px;

    height:auto;

    margin-top: 5%;
    padding-top: 2vw;


    background-color: #ffffff;


    display: flex;

    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

}
#up-arrow{
    width: 80px;
}
#up-arrow:hover{

    border: 3px black solid;
    border-radius: 50%;

}

#thank{

    display: flex;
    line-height: 1.1rem;
    text-align: center;
    font-size: 1.4rem;
    font-weight: bolder;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}

@media screen and (max-width: 1023px){

    #footer-body{


        height: auto;
        
   
        background-color: white;
        padding: 1%;

        margin-top: 15%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

    }
    #thank{
        margin-top: 3%;
        margin-bottom: 3%;
        display: flex;
        text-align: center;
        font-size: 1rem;
        flex-direction: column;
        justify-content: center;
        align-content: center;
    }
    #icons{
        margin-top: 2%;
       

    }


}
