.detail-book-img{
    width: 20%;
    align-self: center;

}

#detail-body-book{
    width: 100%;
    margin-top: 3%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.author{
    text-align: center;
    margin-top: 2%;
    font-size: 3em;
    color: white;
}

@media screen and (max-width: 1023px){

    .author{
        text-align: center;
        margin-top: 8%;
        font-size: 1.5em;
        color: white;
    }

    .detail-book-img{
        width: 50%;
        align-self: center;

    }

}